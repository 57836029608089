import { Link, useNavigate } from "react-router-dom";
import styles from "./RecusadosInfluenciador.module.css";
import IInfluenciador from "../../../../../types/IInfluenciador";
import { useEffect, useState } from "react";
import { collection, doc, getDocs, updateDoc } from "firebase/firestore/lite";
import { db } from "../../../../../db/banco";
import axios from "axios";
import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import CampoEscolha from "../../../../../components/CampoEscolha";
import CampoTexto from "../../../../../components/CampoTexto";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import guardaPaginaAnterior from "../../../../../stores/paginaAnterior";
import { TextField as T, Autocomplete } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import ChoiceField from "../../../../../components/ChoiceField";
import { listaDeCategorias } from "../../../../../utils/listaDeCategorias";
import { listaFiltroModeradorPorInfluenciador } from "../../../../../utils/listas";
import { autocompleteStyles } from "../../../../../utils/styles";

export default function RecusadosInfluenciador() {
    const navigate = useNavigate();
    // Aux para atualizar página
    const [aux, setAux] = useState(0);
    const navitate = useNavigate();
    // recebe e controla os influenciadores
    const [influenciadores, setInfluenciadores] = useState<IInfluenciador[]>([]);
    const [influenciadoresAux, setInfluenciadoresAux] = useState<IInfluenciador[]>([]);
    const [influenciadorFiltrado, setInfluenciadorFiltrado] = useState<IInfluenciador[]>([]);
    // filtro
    const [Tipofiltro, setTipoFiltro] = useState("");
    const listaFiltro = ["email"];
    const [textoFiltro, setTextoFiltro] = useState("");
    // filtro    
    const [filtroPorNome, setFiltroPorNome] = useState("");
    const [filtrarPorEmail, setFiltrarPorEmail] = useState("");
    const [filtrarPorCategoria, setFiltrarPorCategoria] = useState("");
    // Filtros
    const [filtrarPor, setFiltrarPor] = useState("");
    const useCollectionRefInfluenciador = collection(db, "influenciador");
    // atualizar tabela
    const [atualiza, setAtualiza] = useState(false);
    // obtem o influenciador
    useEffect(() => {
        const obterInfluenciadores = async () => {
            const dataBD = await getDocs(useCollectionRefInfluenciador);
            const todosInfluenciadores: IInfluenciador[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as IInfluenciador);
            const analiseInfluenciaodes: IInfluenciador[] = todosInfluenciadores.filter(analise => analise.aceito === "recusado");
            setInfluenciadores(analiseInfluenciaodes);
            setInfluenciadoresAux(analiseInfluenciaodes);

        };
        obterInfluenciadores();
    }, [atualiza]);

    async function editarInfluenciador(id: string, novosDados: any) {
        try {
            if (id) {
                const chamadoDocRef = doc(db, "influenciador", id);

                await updateDoc(chamadoDocRef, novosDados);
                navitate("/moderador/usuarios-recusados");
                setAtualiza(!atualiza);
            }
        } catch (error) {
            alert("Nao deu certo")
        }
    }

    const enviarEmail = async (email: string, nome: string) => {
        try {
            const response = await axios.post(
                "https://us-central1-ism-teste.cloudfunctions.net/enviarEmail",
                {
                    destinatario: email,
                    assunto: "Seu perfil foi aprovado em uma nova triagem!",
                    mensagem: `Oii, ${nome}\n\n` +
                        "Temos uma novidade super legal para te contar!\n\n" +

                        "Você foi aprovado na nossa plataforma!! Incrível, né!?\n\n" +

                        "Periodicamente reavaliamos os perfis cadastrados na nossa plataforma. Realizamos uma\n" +
                        "triagem recente no seu perfil e notamos que ele está cumprindo todos os requisitos para ter\n" +
                        "acesso integral ao ISM\n\n" +

                        "Parabéns, você foi aprovado(a)!\n\n" +

                        "A partir de agora, você poderá visualizar as nossas campanhas, tendo a possibilidade de\n" +
                        "solicitar acesso para participar das que mais te interessarem\n\n" +

                        "Veja as campanhas em: influenciesuamarca.com.br/influenciador/campanhas-disponiveis\n\n" +

                        "Estamos felizes e animados com nossa parceria!\n\n" +

                        "Conte com a gente para influenciar sempre mais\n" +
                        "Equipe ISM"
                }
            );

            const { id } = response.data;
            return id;
        } catch (error) {
            // console.log(error);
        }
    };

    async function aceitarInfluenciador(id: string, email: string, nome: string) {
        const influenciador = {
            aceito: "aceito"
        }
        await editarInfluenciador(id, influenciador);
        enviarEmail(email, nome);
        alert("Influenciador aceito");
    };

    // Paginação

    const [pagina, setPagina] = useState(1);

    const handleChangePagina = (event: any, value: any) => {
        setPagina(value);
    };
    const linhasPorPagina = 10;
    const startIndex = (pagina - 1) * linhasPorPagina;
    const endIndex = startIndex + linhasPorPagina;
    const influenciadoresPaginados = influenciadores.slice(startIndex, endIndex);

    // Filtro
    function filtrarInfluenciadores() {
        if (textoFiltro.length > 0) {
            let resultadoFiltrado: IInfluenciador[] = [...influenciadoresAux];
            resultadoFiltrado = resultadoFiltrado.filter((filtrado) => filtrado.email === textoFiltro);

            if (resultadoFiltrado.length === 1) setInfluenciadorFiltrado(resultadoFiltrado);
            else alert("Esse email não corresponde a essa pesquisa.");

        } else {
            alert("Campo em branco, digite um e-mail pare realizar a pesquisa.")
        }
    }

    // Função para pegar somente depois do /
    function extractAfterSlash(url: any) {
        if (!url) return ''; // Se a URL for nula ou indefinida, retorna uma string vazia
        const parts = url.split('/');
        return parts.length > 1 ? parts.pop() : '';
    }

    useEffect(() => {
        const guardaPagina = guardaPaginaAnterior.getPagina();
        const convertePagina = Number(guardaPagina);

        if (convertePagina > 0) {
            setPagina(convertePagina);
            setAux(convertePagina);
        }
    }, [aux]);
    // Botão editar
    function irParaPerfilEditar(email: string) {
        navigate(`/moderador/editar-perfil-influenciador?id=${email}&pg=${pagina}`);
    }

    function filtrar() {
        if (filtrarPor === "nome") {
            let resultadoFiltrado: IInfluenciador[] = [...influenciadoresAux];
            const recebeFiltro: IInfluenciador[] = resultadoFiltrado.filter(porNome => porNome.nomeCompleto === filtroPorNome);
            setInfluenciadorFiltrado(recebeFiltro);
        }

        if (filtrarPor === "email") {
            let resultadoFiltrado: IInfluenciador[] = [...influenciadoresAux];
            const recebeFiltro: IInfluenciador[] = resultadoFiltrado.filter(porNome => porNome.email === filtrarPorEmail);
            setInfluenciadorFiltrado(recebeFiltro);
        }

        if (filtrarPor === "categoria") {
            let resultadoFiltrado: IInfluenciador[] = [...influenciadoresAux];
            const recebeFiltro: IInfluenciador[] = resultadoFiltrado.filter(porNome => porNome.categoria === filtrarPorCategoria);
            setInfluenciadorFiltrado(recebeFiltro);

            if (recebeFiltro.length === 0) alert("Não existe influenciador com essa categoria aceito na plataforma!");
        }
    }

    return (
        <section className={styles.RecusadosInfluenciador}>
            <h3> Filtrar influenciadores por: </h3>

            <aside>
                <ChoiceField
                    title=""
                    control={setFiltrarPor}
                    icon={<FilterAltIcon fontSize="large" />}
                    list={listaFiltroModeradorPorInfluenciador}
                    defaultValue="Escolha"
                />

                {filtrarPor === "nome" &&
                    <>
                        <Autocomplete
                            sx={autocompleteStyles}
                            freeSolo
                            options={influenciadores.map((option) => option.nomeCompleto)}
                            onInputChange={(event, newInputValue) => {
                                setFiltroPorNome(newInputValue);
                            }}
                            renderInput={(params) => (
                                <T

                                    {...params}
                                    label="Nome do Influenciador"
                                    placeholder="Digite o nome"
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <>
                                                <PersonIcon fontSize="large" />
                                                {params.InputProps.startAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            )}
                        />

                        <button className="btn btn-info btn-lg mb-2 ml-3" onClick={filtrar}> Filtrar </button>

                    </>}

                {filtrarPor === "email" &&
                    <>
                        <Autocomplete
                            sx={autocompleteStyles}
                            freeSolo
                            options={influenciadores.map((option) => option.email)}
                            onInputChange={(event, newInputValue) => {
                                setFiltrarPorEmail(newInputValue);
                            }}
                            renderInput={(params) => (
                                <T

                                    {...params}
                                    label="Email do Influenciador"
                                    placeholder="Digite o e-mail"
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <>
                                                <PersonIcon fontSize="large" />
                                                {params.InputProps.startAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            )}
                        />

                        <button className="btn btn-info btn-lg mb-2 ml-3" onClick={filtrar}> Filtrar </button>

                    </>}

                {filtrarPor === "categoria" &&
                    <>
                        <Autocomplete
                            sx={autocompleteStyles}
                            freeSolo
                            options={listaDeCategorias.map((option) => option)}
                            onInputChange={(event, newInputValue) => {
                                setFiltrarPorCategoria(newInputValue);
                            }}
                            renderInput={(params) => (
                                <T

                                    {...params}
                                    label="Categoria do influenciador"
                                    placeholder="Digite a categoria"
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <>
                                                <PersonIcon fontSize="large" />
                                                {params.InputProps.startAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            )}
                        />

                        <button className="btn btn-info btn-lg mb-2 ml-3" onClick={filtrar}> Filtrar </button>

                    </>}
            </aside>

            {influenciadorFiltrado.length > 0 && <h6> Resultado da pesquisa </h6>}

            {influenciadorFiltrado.map((influenciadorEncontrado, index) => (
                <div className="table-responsive">
                    <table className="table table-light table-striped text-center">
                        <thead>
                            <tr>
                                <th scope="col" className="text-white" style={{ width: '33%' }}>Nome</th>
                                <th scope="col" className="text-white d-none d-sm-table-cell" style={{ width: '33%' }}>@</th>
                                <th scope="col" className="text-white" style={{ width: '33%' }}>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr key={influenciadorEncontrado.id} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
                                <td className="text-wrap" style={{ wordBreak: 'normal', width: '33%' }}>
                                    <a href={`/moderador/ver-perfil-influenciador?id=${influenciadorEncontrado.id}&pg=${pagina}`}>{influenciadorEncontrado.nomeCompleto}</a>
                                </td>
                                <td className="text-wrap d-none d-sm-table-cell" style={{ wordBreak: 'normal', width: '33%' }}>
                                    {extractAfterSlash(influenciadorEncontrado.arrobaPrincipal)}
                                </td>
                                <td className={`${styles.CelulaBotoes} text-wrap`} style={{ width: '33%' }}>
                                    <div className="d-flex flex-column flex-sm-row justify-content-center">
                                        <button className="btn btn-success btn-lg mb-2 mb-sm-0 me-sm-1" onClick={() => aceitarInfluenciador(influenciadorEncontrado.id, influenciadorEncontrado.email, influenciadorEncontrado.nomeCompleto)}>Aceitar</button>
                                        <button className="btn btn-info btn-lg" onClick={() => irParaPerfilEditar(influenciadorEncontrado.email)}>Editar</button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>


                </div>
            ))}

            <h6> Lista dos influenciadores em espera </h6>

            {influenciadoresPaginados.length > 0 ? <Stack spacing={2}>
                <table className="table table-light table-striped text-center">
                    <thead>
                        <tr>
                            <th scope="col" className="text-white" style={{ width: '33%' }}>Nome</th>
                            <th scope="col" className="text-white d-none d-sm-table-cell" style={{ width: '33%' }}>@</th>
                            <th scope="col" className="text-white" style={{ width: '33%' }}>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {influenciadoresPaginados.map((cel, index) => (
                            <tr key={cel.id} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
                                <td className="text-wrap" style={{ wordBreak: 'normal', width: '33%' }}>
                                    <a href={`/moderador/ver-perfil-influenciador?id=${cel.id}&pg=${pagina}`}>{cel.nomeCompleto}</a>
                                </td>
                                <td className="text-wrap d-none d-sm-table-cell" style={{ wordBreak: 'normal', width: '33%' }}>
                                    {extractAfterSlash(cel.arrobaPrincipal)}
                                </td>
                                <td className={`${styles.CelulaBotoes} text-wrap`} style={{ width: '33%' }}>
                                    <div className="d-flex flex-column flex-sm-row justify-content-center">
                                        <button className="btn btn-success btn-lg mb-2 mb-sm-0 me-sm-1" onClick={() => aceitarInfluenciador(cel.id, cel.email, cel.nomeCompleto)}>Aceitar</button>
                                        <button className="btn btn-info btn-lg" onClick={() => irParaPerfilEditar(cel.email)}>Editar</button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <Pagination
                    count={Math.ceil(influenciadores.length / linhasPorPagina)}
                    page={pagina}
                    onChange={handleChangePagina}
                />
            </Stack> : <p> Não existe influenciadores nessa pesquisa </p>}
        </section>
    )
}