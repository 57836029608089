import { collection, doc, getDocs, updateDoc } from "firebase/firestore/lite";
import { useEffect, useState } from "react";
import { db } from "../../../../db/banco";
import IAnunciante from "../../../../types/IAnunciante";
import styles from "./PlanosAnunciantes.module.css";
import { Stack, Pagination, Tooltip, Alert, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Modals from "../../../../utils/modals";
import ChoiceField from "../../../../components/ChoiceField";
import { listaPlanosISM } from "../../../../utils/listas";
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import AreaTextoMenor from "../../../../components/AreaTextoMenor";
import TextsmsIcon from '@mui/icons-material/Textsms';
import { createNotification, sendEmail } from "../../../../utils/functions";
import Graphics from "../../../../components/Graphics";


export default function PlanosAnunciantes() {
    const isMobile = useMediaQuery('(max-width:700px)');
    // Recebe e controla os anunciantes
    const [anunciantes, setAnunciantes] = useState<IAnunciante[]>([]);
    const [anunciantesAux, setAnunciantesAux] = useState<IAnunciante[]>([]);
    const [anuncianteFiltrado, setAnuncianteFiltrado] = useState<IAnunciante[]>([]);
    // atualizar tabela
    const [atualiza, setAtualiza] = useState(false);
    // Controla os modais
    const [abrirModalPlano, setModalPlano] = useState(false);
    const [abrirModalComunicar, setModalComunicar] = useState(false);
    // Modal
    const [nomeModal, setNomeModal] = useState("");
    const [planoModal, setPlanoModal] = useState("");
    const [idAnuncianteModal, setIdAnuncianteModal] = useState("");
    const [emailModal, setEmailModal] = useState("");
    // Alterar plano
    const [selecionarPlano, setSelecionarPlano] = useState("");
    const [salvarPlano, setSalvarPlano] = useState(0);
    // Modal comunicar
    const [textoModalComunicar, setTextoModalComunicar] = useState("");
    // Alertas
    const [campoEmBranco, setCampoEmBranco] = useState(false);
    const [planoAlteradoComSucesso, setPlanoAlteradoComSucesso] = useState(false);
    const [mensagemEnviadaComSucesso, setMensagemEnviadaComSucesso] = useState(false);
    // Gráfico
    const [numeroDeAnunciantesTrial, setNumeroDeAnunciantesTrial] = useState(0);
    const [numeroDeAnunciantesAction, setNumeroDeAnunciantesAction] = useState(0);
    const [numeroDeAnunciantesPremium, setNumeroDeAnunciantesPremium] = useState(0);

    const useCollectionRefAnunciante = collection(db, "anunciante");
    // obtem os anunciantes
    useEffect(() => {
        const obterAnunciantes = async () => {
            const dataBD = await getDocs(useCollectionRefAnunciante);
            const todosAnunciantes: IAnunciante[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as IAnunciante);
            const anunciantesAceitos: IAnunciante[] = todosAnunciantes.filter(analise => analise.aceito === "aceito");
            setAnunciantes(anunciantesAceitos);
            setAnunciantesAux(anunciantesAceitos);

            // Gerar o gráfico
            const anunciantesTrial = anunciantesAceitos.filter(trial => trial.planoISM === 0);
            setNumeroDeAnunciantesTrial(anunciantesTrial.length);

            const anunciantesAction = anunciantesAceitos.filter(trial => trial.planoISM === 1);
            setNumeroDeAnunciantesAction(anunciantesAction.length);

            const anunciantesPremium = anunciantesAceitos.filter(trial => trial.planoISM === 2);
            setNumeroDeAnunciantesPremium(anunciantesPremium.length);
        };
        obterAnunciantes();
    }, [atualiza]);

    // Paginação
    const [pagina, setPagina] = useState(1);

    const handleChangePagina = (event: any, value: any) => {
        setPagina(value);
    };
    const linhasPorPagina = 10;
    const startIndex = (pagina - 1) * linhasPorPagina;
    const endIndex = startIndex + linhasPorPagina;
    const anunciantesPaginados = anunciantes.slice(startIndex, endIndex);

    //Modal Plano
    const handleOpenPlano = (nomeAnunciante: string, planoAtual: number, idAnunciante: string) => {
        setNomeModal(nomeAnunciante);
        setIdAnuncianteModal(idAnunciante);
        const planos = ["Trial", "Action", "Premium"];
        setPlanoModal(planos[planoAtual]);
        setModalPlano(true);
    }

    const handleClosePlano = () => {
        setModalPlano(false);
        setPlanoAlteradoComSucesso(false);
    }
    // Alterar plano
    function alterarPlano() {
        const planoTrocado = listaPlanosISM.indexOf(selecionarPlano);
        const dataHoje = new Date();
        const dataHojeFormata = dataHoje.toLocaleDateString();
        const anunciante = {
            planoISM: planoTrocado,
            planoTrocado: dataHojeFormata
        };
        editarAnunciante(anunciante);
    }

    async function editarAnunciante(novosDados: any) {
        try {
            const anuncianteRef = doc(db, "anunciante", idAnuncianteModal);
            await updateDoc(anuncianteRef, novosDados);
            setAtualiza(!atualiza);
            // setModalPlano(false);
            setPlanoAlteradoComSucesso(true);
        } catch (error) {

        }
    }
    // Modal comunicar
    const handleCloseComunicar = () => {
        setModalComunicar(false);
        setMensagemEnviadaComSucesso(false);
    }
    const handleOpenComunicar = (nomeAnunciante: string, emailAnunciante: string) => {
        setNomeModal(nomeAnunciante);
        setEmailModal(emailAnunciante)
        setModalComunicar(true);
    }

    function enviar() {
        if (textoModalComunicar === "") {
            setCampoEmBranco(true);
        } else {
            setCampoEmBranco(false);
            createNotification(textoModalComunicar, "anunciante", emailModal, "https://influenciesuamarca.com.br/anunciante/inicio");
            sendEmail(emailModal, "Planos ISM - Influencie sua Marca", textoModalComunicar);
            // setModalComunicar(false);
            setMensagemEnviadaComSucesso(true);
            setTextoModalComunicar("");
        }
    }

    return (
        <section className={styles.PlanosAnunciantes}>
            <h2> Planos dos Anunciantes </h2>

            {anunciantesPaginados.length > 0 ? <Stack spacing={2}>
                {!isMobile ? <table className="table table-light table-striped text-center">
                    <thead>
                        <tr>
                            <th scope="col" className="text-white" style={{ width: '33%' }}> Nome </th>
                            <th scope="col" className="text-white" style={{ width: '33%' }} >Razão social </th>
                            <th scope="col" className="text-white" style={{ width: '33%' }} > Plano </th>
                            <th scope="col" className="text-white" style={{ width: '33%' }} > Duração </th>
                            <th scope="col" className="text-white" style={{ width: '33%' }}> Administrar </th>
                            <th scope="col" className="text-white" style={{ width: '33%' }}> Comunicar </th>
                        </tr>
                    </thead>
                    <tbody>
                        <Modals
                            openModal={abrirModalPlano}
                            closeModal={handleClosePlano}
                            title="Trocar plano do anunciante."
                            description={`Alterando plano do: ${nomeModal}`}
                        >
                            <p> O plano atual desse anunciante é: {planoModal}</p>
                            <ChoiceField
                                title="Escolha entre os planos"
                                list={listaPlanosISM}
                                control={setSelecionarPlano}
                                defaultValue={planoModal}
                                icon={<AutoAwesomeMotionIcon fontSize="large" />}
                            />
                            {planoAlteradoComSucesso && <Alert variant="outlined" severity="success">
                                Plano alterado com sucesso!
                            </Alert>}

                            <button className="btn btn-info mt-4 me-3" onClick={alterarPlano}> Alterar </button>
                            <button className="btn btn-secondary mt-4 me-3" onClick={handleClosePlano}> Voltar </button>
                        </Modals>

                        <Modals
                            openModal={abrirModalComunicar}
                            closeModal={handleCloseComunicar}
                            title={`Enviar e-mail para ${nomeModal}.`}
                            description="E será criado uma notificação para o mesmo."
                        >
                            <AreaTextoMenor
                                titulo="Digite o conteúdo do e-mail."
                                controle={setTextoModalComunicar}
                                valor={textoModalComunicar}
                                icone={<TextsmsIcon fontSize="large" />}
                                colunas={35}
                                linhas={3}
                            />

                            {mensagemEnviadaComSucesso && <Alert variant="outlined" severity="success">
                                Mensagem enviada com sucesso!
                            </Alert>}

                            {campoEmBranco && <Alert variant="filled" severity="warning">
                                Digite uma mensagem para ser enviada.
                            </Alert>}

                            <button className="btn btn-info mt-4 me-3" onClick={enviar}> Enviar </button>
                            <button className="btn btn-secondary mt-4 me-3" onClick={handleCloseComunicar}> Voltar </button>
                        </Modals>
                        {anunciantesPaginados.map((cel, index) => (
                            <tr key={cel.id} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
                                <td style={{ width: '33%' }}>
                                    <a href={`/moderador/ver-perfil-anunciante?id=${cel.id}&pg=${pagina}&tp=rec`}>
                                        {cel.nomeCompleto}
                                    </a>
                                </td>
                                <td style={{
                                    width: '33%',
                                    maxWidth: '300px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap'
                                }}>
                                    {cel.razaoSocial}
                                </td>
                                <td style={{ width: '33%' }}> {cel.planoISM === 0 ? <Tooltip title="Plano grátis durante 1 mês." arrow classes={{ tooltip: styles.tooltip }}>
                                    <label> Trial </label>
                                </Tooltip> :
                                    cel.planoISM === 1 ? <Tooltip title="Plano especial clientes Actionpay." arrow classes={{ tooltip: styles.tooltip }}>
                                        <label> Action </label>
                                    </Tooltip> :
                                        <Tooltip title="Plano pago mensalmente." arrow classes={{ tooltip: styles.tooltip }}>
                                            <label> Premium </label>
                                        </Tooltip>}
                                </td>
                                <td style={{ width: '33%' }}>
                                    {cel.planoISM === 0 ?
                                        (() => {
                                            const [day, month, year] = cel.dataCriacao.split('/');
                                            const initialDate = new Date(Number(year), Number(month) - 1, Number(day));
                                            const oneMonthLater = new Date(initialDate);
                                            oneMonthLater.setMonth(oneMonthLater.getMonth() + 1);

                                            const today = new Date();
                                            const diffTime = oneMonthLater.getTime() - today.getTime();
                                            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Converte milissegundos para dias

                                            return diffDays > 0 ? `${diffDays} dias restantes.` : <span> Expirado </span>;
                                        })() :
                                        cel.planoISM === 1 ? "Indeterminado" : `Desde: ${cel.planoTrocado}`}
                                </td>

                                <td style={{ width: '33%' }}>
                                    <ManageAccountsIcon onClick={() => handleOpenPlano(cel.nomeCompleto, cel.planoISM, cel.id)} fontSize="large" />
                                </td>
                                <td style={{ width: '33%' }}>
                                    <ForwardToInboxIcon onClick={() => handleOpenComunicar(cel.nomeCompleto, cel.email)} fontSize="large" />
                                </td>
                                
                            </tr>
                            
                        ))}
                    </tbody>
                </table> : <div className="card-container">
                    {anunciantesPaginados.map((cel, index) => (
                        <div key={cel.id} className="card mb-3 mt-3" style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
                            <div className="card-body">
                                <h5 className="card-title">
                                    <a href={`/moderador/ver-perfil-anunciante?id=${cel.id}&pg=${pagina}&tp=rec`}>
                                        {cel.nomeCompleto}
                                    </a>
                                </h5>
                                <p className="card-text"><strong>Razão Social:</strong> {cel.razaoSocial}</p>
                                <p className="card-text">
                                    <strong>Plano:</strong>
                                    {cel.planoISM === 0 ? (
                                        <Tooltip title="Plano grátis durante 1 mês." arrow>
                                            <span>Trial</span>
                                        </Tooltip>
                                    ) : cel.planoISM === 1 ? (
                                        <Tooltip title="Plano especial clientes Actionpay." arrow>
                                            <span>Action</span>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title="Plano pago mensalmente." arrow>
                                            <span>Premium</span>
                                        </Tooltip>
                                    )}
                                </p>
                                <p className="card-text">
                                    <strong>Duração:</strong>
                                    {cel.planoISM === 0 ? (() => {
                                        const [day, month, year] = cel.dataCriacao.split('/');
                                        const initialDate = new Date(Number(year), Number(month) - 1, Number(day));
                                        const oneMonthLater = new Date(initialDate);
                                        oneMonthLater.setMonth(oneMonthLater.getMonth() + 1);
                                        const today = new Date();
                                        const diffTime = oneMonthLater.getTime() - today.getTime();
                                        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                                        return diffDays > 0 ? `${diffDays} dias restantes.` : <span>Expirado</span>;
                                    })() : cel.planoISM === 1 ? "Indeterminado" : `Desde: ${cel.planoTrocado}`}
                                </p>
                                <p className="card-text">
                                    <strong>Administrar:</strong>
                                    <ManageAccountsIcon onClick={() => handleOpenPlano(cel.nomeCompleto, cel.planoISM, cel.id)} fontSize="large" />
                                </p>
                                <p className="card-text">
                                    <strong>Comunicar:</strong>
                                    <ForwardToInboxIcon onClick={() => handleOpenComunicar(cel.nomeCompleto, cel.email)} fontSize="large" />
                                </p>
                                
                            </div>
                            
                        </div>
                        
                    ))}
                    <Modals
                            openModal={abrirModalPlano}
                            closeModal={handleClosePlano}
                            title="Trocar plano do anunciante."
                            description={`Alterando plano do: ${nomeModal}`}
                        >
                            <p> O plano atual desse anunciante é: {planoModal}</p>
                            <ChoiceField
                                title="Escolha entre os planos"
                                list={listaPlanosISM}
                                control={setSelecionarPlano}
                                defaultValue={planoModal}
                                icon={<AutoAwesomeMotionIcon fontSize="large" />}
                            />
                            {planoAlteradoComSucesso && <Alert variant="outlined" severity="success">
                                Plano alterado com sucesso!
                            </Alert>}

                            <button className="btn btn-info mt-4 me-3" onClick={alterarPlano}> Alterar </button>
                            <button className="btn btn-secondary mt-4 me-3" onClick={handleClosePlano}> Voltar </button>
                        </Modals>

                        <Modals
                            openModal={abrirModalComunicar}
                            closeModal={handleCloseComunicar}
                            title={`Enviar e-mail para ${nomeModal}.`}
                            description="E será criado uma notificação para o mesmo."
                        >
                            <AreaTextoMenor
                                titulo="Digite o conteúdo do e-mail."
                                controle={setTextoModalComunicar}
                                valor={textoModalComunicar}
                                icone={<TextsmsIcon fontSize="large" />}
                                colunas={35}
                                linhas={3}
                            />

                            {mensagemEnviadaComSucesso && <Alert variant="outlined" severity="success">
                                Mensagem enviada com sucesso!
                            </Alert>}

                            {campoEmBranco && <Alert variant="filled" severity="warning">
                                Digite uma mensagem para ser enviada.
                            </Alert>}

                            <button className="btn btn-info mt-4 me-3" onClick={enviar}> Enviar </button>
                            <button className="btn btn-secondary mt-4 me-3" onClick={handleCloseComunicar}> Voltar </button>
                        </Modals>
                </div>
                }

                <Pagination
                    count={Math.ceil(anunciantes.length / linhasPorPagina)}
                    page={pagina}
                    onChange={handleChangePagina}
                />
            </Stack> : <p> Não existe anunciantes nessa pesquisa </p>}

            <h2> Comparativo dos planos de anunciantes </h2>

            <Graphics
                size={isMobile ? "360" : "500"}
                trial={numeroDeAnunciantesTrial}
                action={numeroDeAnunciantesAction}
                premium={numeroDeAnunciantesPremium}
            />

        </section>
    )
}