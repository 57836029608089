import { useState } from "react";
import ICriarCampanha from "../../../../../types/ICriarCampanha";
import { Alert, Pagination, Stack, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import styles from "./TableDesktop.module.css";
import EditIcon from '@mui/icons-material/Edit';
import PostAddIcon from '@mui/icons-material/PostAdd';

interface TabelaDesktopProps {
    data: ICriarCampanha[],
}

export default function TableDesktop({ data }: TabelaDesktopProps) {
    // Paginação
    const [page, setPage] = useState(1);
    const handleChangePagina = (event: any, value: any) => {
        setPage(value);
    };

    const linesForPage = 10;
    const startIndex = (page - 1) * linesForPage;
    const endIndex = startIndex + linesForPage;
    const campanhasPaginados = data.slice(startIndex, endIndex);

    return (
        <Stack spacing={2}>
            {campanhasPaginados.length !== 0 ? <> <table className="table table-light table-striped text-center">
                <thead>
                    <tr>
                        <th scope="col" className="text-white d-none d-sm-table-cell" style={{ textAlign: 'center' }}> Nome </th>
                        <th scope="col" className="text-white d-none d-sm-table-cell" style={{ textAlign: 'center' }}> Tipo </th>
                        <th scope="col" className="text-white d-none d-sm-table-cell" style={{ textAlign: 'center' }}> Ações </th>
                    </tr>
                </thead>
                <tbody>
                    {campanhasPaginados.map((campaign, index) => (
                        <tr key={campaign.id} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
                            <td className="align-middle text-center">
                                <a href={`/moderador/detalhes-campanha-performance?id=${campaign.id}`} className={styles.aTable}>
                                    {campaign.nomeDaCampanha}
                                </a>
                            </td>
                            <td className="align-middle text-center">
                                {campaign.tipoDeCampanha}
                            </td>
                            <td>
                                <a href={`/moderador/editar-campanha?id=${campaign.id}`} className={styles.LinkIconEdit}> <EditIcon /> </a>
                                <Tooltip title="Adicionar banners." arrow classes={{ tooltip: styles.tooltip }}>
                                    <a href={`/moderador/adicionar-banners?id=${campaign.id}&email=${campaign.anuncianteEmail}`}> <PostAddIcon className={styles.IconeAdicionarBanners} /> </a>
                                </Tooltip>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
                <nav className={styles.Pagination}>
                    <Pagination
                        count={Math.ceil(data.length / linesForPage)}
                        page={page}
                        onChange={handleChangePagina}
                    /></nav> </> : <Alert severity="warning"> Não existem campanhas sem performance no momento. </Alert>}
        </Stack>
    )
}


