import { useState, useEffect } from "react";
import { ref, uploadBytesResumable, getDownloadURL, listAll } from "firebase/storage";
import { storage } from "../../../../db/banco";
import styles from "./SendBanner.module.css";
import { v4 as uuidv4 } from "uuid";
import { Alert, Pagination } from "@mui/material";

interface BannerData {
    url: string;
    validityDate: string;
}

interface SendBannerProps {
    email: string;
    idCampaign: string;
}

export default function SendBanner({ email, idCampaign }: SendBannerProps) {
    const [file, setFile] = useState<File | null>(null);
    const [validityDate, setValidityDate] = useState<string>("");
    const [banner, setBanner] = useState<string>("");
    const [bannersList, setBannersList] = useState<BannerData[]>([]);
    const [lastDateBanner, setLastDateBanner] = useState("");
    // Alertas
    const [bannerSentAlert, setBannerSentAlert] = useState(false);

    // Upload do arquivo
    const uploadBanner = (event: React.FormEvent) => {
        event.preventDefault();

        if (!file || !validityDate) {
            alert("Selecione um arquivo e uma data de validade!");
            return;
        }

        // Gerar um ID único para o nome do arquivo com a data de validade
        const formattedDate = validityDate.split("-").reverse().join("-");  // Formato correto para a data
        const uniqueFileName = `${uuidv4()}_${formattedDate}.${file.name.split(".").pop()}`;

        // Referência para o Firebase Storage
        const storageRef = ref(storage, `anunciante/${email}/campanhas/${idCampaign}/banners/${uniqueFileName}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            "state_changed",
            () => { },
            (error) => {
                alert("Erro ao enviar o arquivo: " + error.message);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    const newBanner = { url, validityDate };
                    setBanner(url);
                    setBannersList((prev) => [newBanner, ...prev]);
                    // alert("Arquivo enviado com sucesso!");
                    setLastDateBanner(validityDate);
                    setFile(null);
                    setValidityDate("");
                    setBannerSentAlert(true);
                });
            }
        );
    };

    // Carregar lista de banners já salvos no Firebase
    useEffect(() => {
        const storagePath = ref(storage, `anunciante/${email}/campanhas/${idCampaign}/banners/`);
        listAll(storagePath).then((res) => {
            const urls = res.items.map((item) => getDownloadURL(item));
            Promise.all(urls).then((downloadURLs) => {
                const banners = downloadURLs.map((url) => {
                    // Cortar a data do nome do arquivo
                    const parts = url.split("_");
                    const datePart = parts[parts.length - 1].split(".")[0];  // Pega a data que está no nome do arquivo
                    return { url, validityDate: datePart };
                });
                setBannersList(banners);
            });
        });
    }, [email, idCampaign]);

    // Função para formatar a data para o formato dd/mm/yyyy
    const formatDate = (dateString: string) => {
        if (!dateString) return "Data inválida";

        // Cortar a data no formato yyyy-mm-dd
        const [year, month, day] = dateString.split("-");

        // Formatar como dd/mm/yyyy
        return `${day}/${month}/${year}`;
    };

    // Paginação
    const [page, setPage] = useState(1);

    const handleChangePagina = (event: any, value: any) => {
        setPage(value);
    };
    const linhasPorPagina = 20;
    const startIndex = (page - 1) * linhasPorPagina;
    const endIndex = startIndex + linhasPorPagina;
    const bannerPageds = bannersList.slice(startIndex, endIndex);

    return (
        <form onSubmit={uploadBanner} className={styles.SendBanner}>
            <h3> Insira o banner </h3>

            <aside>
                <input
                    type="file"
                    onChange={(e) => setFile(e.target.files?.[0] || null)}
                    className="form-control mb-3"
                    required
                />
                <input
                    type="date"
                    value={validityDate}
                    onChange={(e) => setValidityDate(e.target.value)}
                    className="form-control mb-3"
                    required
                />
                <button type="submit" className="btn btn-success">
                    Enviar
                </button>
            </aside>

            {bannerSentAlert && <Alert severity="success"> Banner enviado com sucesso! </Alert>}

            {banner && (
                <div>
                    <h4> Último banner enviado: </h4>
                    <a href={banner} target="_blank" rel="noopener noreferrer">
                        <img src={banner} alt="Banner" className={styles.bannerImage} />
                    </a>
                    <p>Validade: {lastDateBanner.split("-").reverse().join("/")}</p>
                </div>
            )}

            {bannersList.length !== 0 ? <h3> Lista de banners enviados </h3> : <Alert severity="warning"> Nenhum banner enviado! </Alert>}

            <div className={styles.imageList}>
                {bannerPageds.map((bannerData, index) => (
                    <div key={index} className={styles.imageItem}>
                        <a href={bannerData.url} target="_blank" rel="noopener noreferrer">
                            <img src={bannerData.url} alt={`Banner ${index + 1}`} className={styles.bannerImage} />
                        </a>
                        <p>Validade: {bannerData.validityDate.split("-").join("/")}</p>
                    </div>
                ))}
            </div>

            <Pagination
                count={Math.ceil(bannersList.length / linhasPorPagina)}
                page={page}
                onChange={handleChangePagina}
            />
        </form>
    );
}
