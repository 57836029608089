import React, { useEffect, useState } from 'react';
import { ITransactionID } from '../../../../types/ITransactionID';
import { formatarData } from '../functions';
import FormatarDinheiro from '../FormatarDinheiro';
import { obterTodosInfluenciadores } from '../../../../utils/db';
import IInfluenciador from '../../../../types/IInfluenciador';
import styles from "./TabelaTransacao.module.css"
import { buscarObjetivos } from '../../../../utils/api';
import { Action } from '../../../../interfaces/DadosEstatisticasProps';

interface TabelaTransacaoProps {
    transactionIDPaginados: ITransactionID[];
    tipoUsuario: string;
    idCampanhaActionpay: string;
    filter?: string
}

const TabelaTransacao: React.FC<TabelaTransacaoProps> = ({ transactionIDPaginados, tipoUsuario, idCampanhaActionpay, filter }) => {
    // recebe o influenciador
    const [Influenciadores, setInfluenciadores] = useState<IInfluenciador[]>([]);
    // Carrega dados dos influenciadores
    useEffect(() => {
        const carregarInfluenciadores = async () => {
            const todosInfluenciadores = await obterTodosInfluenciadores();
            setInfluenciadores(todosInfluenciadores)
        };
        carregarInfluenciadores();
    }, []);

    const [actions, setActions] = useState<Action[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [objetivosDisponiveis, setObjetivosDisponiveis] = useState<{ id: number; name: string }[]>([]);

    useEffect(() => {
        const fetchDataObjetivos = async () => {
            console.log('useEffect executado'); // Log para verificar execução do useEffect

            if (!idCampanhaActionpay) {
                console.warn('Nenhum idCampanhaActionpay fornecido');
                return;
            }

            try {
                setLoading(true);
                console.log(`Buscando objetivos para a campanha: ${idCampanhaActionpay}`);
                const response = await buscarObjetivos(idCampanhaActionpay);

                if (response) {
                    console.log('Dados recebidos da API aim:', response.result.offers[0].aims.map);
                    const actions = response.result?.actions || [];
                    setActions(actions);
                    // Verifica se `aims` é um array antes de processar
                    const aims = response.result.offers[0].aims;
                    if (Array.isArray(aims)) {
                        // Extrai pares { id, name } e armazena no array
                        const pairsArray = aims.map((aim: { id: number; name: string }) => ({
                            id: aim.id,
                            name: aim.name,
                        }));

                        console.log('Array de pares:', pairsArray);
                        setObjetivosDisponiveis(pairsArray);
                        return pairsArray; // Retorna o array de pares, caso necessário
                    } else {
                        console.warn('Aims não é um array:', aims);
                        return [];
                    }

                } else {
                    console.warn('Nenhuma resposta válida recebida da API');
                    setError('Erro ao carregar os dados.');
                }
            } catch (error) {
                console.error('Erro durante a chamada da API:', error);
                setError('Erro na chamada da API.');
            } finally {
                console.log('Finalizando carregamento');
                setLoading(false);
            }
        };

        fetchDataObjetivos();
    }, [idCampanhaActionpay]);

    return (
        <div className={styles.TabelaTransacao}>
            <table className="table table-light table-striped">
                <thead>
                    <tr>
                        <th className="text-center">ID da transação</th>
                        <th className="text-center">Data e hora</th>
                        <th className="text-center">Status</th>
                        <th className="text-center">Valor da transação</th>
                        <th className="text-center">Comissão</th>
                        {tipoUsuario !== "influ" && <th className="text-center">Influenciador</th>}
                        <th className="text-center"> Objetivo </th>
                    </tr>
                </thead>
                <tbody>
                    {transactionIDPaginados
                        .filter(action => {
                            if (!filter?.trim()) return true; // Se `filter` estiver vazio ou não enviado, exibe todos.

                            // Procura o objetivo pelo nome no array `objetivosDisponiveis`
                            const objetivoEncontrado = objetivosDisponiveis.find(objetivo => objetivo.name === filter);

                            // Retorna true se o objetivo for encontrado e o `id` coincidir com `action.aim`
                            return objetivoEncontrado ? objetivoEncontrado.id === Number(action.aim) : false;
                        })
                        .map((action, index) => (
                            <tr
                                key={action.uniqueid}
                                style={{
                                    backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white',
                                }}
                            >
                                <td data-label="ID da transação">{action.apid}</td>
                                <td data-label="Data e hora">{formatarData(action.date)}</td>
                                <td
                                    data-label="Status"
                                    className={`${action.status.id === 400 ? 'desativadoMobile' : action.status.id === 300
                                        ? 'processando'
                                        : 'ativoMobile'}`}
                                >
                                    {action.status.id === 400
                                        ? 'Rejeitada'
                                        : action.status.id === 300
                                            ? 'Em espera'
                                            : 'Aprovada'}
                                </td>
                                <td data-label="Valor da transação" className="text-center">
                                    <FormatarDinheiro price={action.price} currency={action.priceCurrency} />
                                </td>
                                <td data-label="Comissão" className="text-center">
                                    <FormatarDinheiro price={action.payment} currency={action.priceCurrency} />
                                </td>
                                {tipoUsuario !== "influ" && (
                                    <td data-label="Influenciador" className="text-center">
                                        {(() => {
                                            const influenciador = Influenciadores.find(
                                                influ => influ.codigoActionpayInfluenciador === Number(action.subId1)
                                            );
                                            return influenciador ? (
                                                <span>{influenciador.nomeCompleto}</span>
                                            ) : 'Nome não encontrado';
                                        })()}
                                    </td>
                                )}
                                <td data-label="Objetivo" className="text-center">
                                    {objetivosDisponiveis.find(
                                        objetivo => objetivo.id === Number(action.aim)
                                    )?.name || "Carregando..."}
                                </td>
                            </tr>
                        ))}

                </tbody>
            </table>
        </div>

    );
};

export default TabelaTransacao;
