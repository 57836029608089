import { useLocation, useNavigate } from "react-router-dom";
import styles from "./EditarImagemCampanhaAnunciante.module.css";
import ImageSearchOutlinedIcon from '@mui/icons-material/ImageSearchOutlined';

import { useEffect, useState } from "react";
import { collection } from "firebase/firestore/lite";

import { getDownloadURL, getStorage, listAll, ref, uploadBytesResumable } from "firebase/storage";
import CampoTexto from "../../../../components/CampoTexto";
import { db, storage } from "../../../../db/banco";
import autenticaStore from "../../../../stores/autentica.store";

export default function EditarImagemCampanhaModerador() {
    // Pegar parametro da página
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const parametro = params.get("id"); // Parâmetro da página
    const emailAnunciante = params.get("emailAnun"); // Parâmetro da página
    console.log("id", parametro);
    
    // Usado para navegar entre rotas
    const navigate = useNavigate();
    const [imagem, setImagem] = useState("");
    const [downloadImagem, setDownloadImagem] = useState("");
    // banco de dados
    const useCollectionRef = collection(db, "anunciante");

    const email = autenticaStore.getEmail();
    console.log(email);


    // Lista caminho, edita e fazer download da imagem
    useEffect(() => {  
        console.log(email, parametro);
              
        const storage = getStorage();
        const listRef = ref(storage, `gs://ism-teste.appspot.com/anunciante/${emailAnunciante}/campanhas/${parametro}`);
        const listaCaminhosPdfs: any = [];

        const fetchData = async () => {
            try {
                const res = await listAll(listRef);
                res.items.forEach((itemRef => {
                    listaCaminhosPdfs.push(itemRef.fullPath);
                }));                

                if (listaCaminhosPdfs.length > 0) {
                    const url = await getDownloadURL(ref(storage, listaCaminhosPdfs[listaCaminhosPdfs.length - 1]));
                    const xhr = new XMLHttpRequest();
                    xhr.responseType = 'blob';
                    xhr.open('GET', url);
                    xhr.send();
                    setDownloadImagem(url);
                }
            } catch (error) {
                console.error('Erro:', error);
            }
        };
        fetchData();

    }, []);

    // subir foto    
    const uploadFoto = (event: any) => {
        event.preventDefault();
        const file = event.target[0]?.files[0];
        if (!file) return;

        const storageRef = ref(storage, `anunciante/${email}/campanhas/${parametro}/imagemDaCampanha`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            "state_changed",
            snapshot => {
            },
            error => {
                alert(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then(url => {
                    setImagem(url);
                    alert(`Imagem enviado com sucesso!`);
                    // if (fileInputRef.current) {
                    //     fileInputRef.current.value = '';
                    // }
                    navigate("/anunciante/minhas-campanhas");
                })
            }
        )
    }

    console.log(imagem);

    return (
        <section className={styles.EditarImagemCampanhaAnunciante}>            
            <h2> A imagem que vai da vida a sua campanha! </h2>
            <h2> Mude sua imagem, caso precise </h2>
            <form onSubmit={uploadFoto}>
                <img src={downloadImagem} alt="foto de perfil" />
                {/* <h3> Você pode ver a imagem e outras informações em, </h3>
                <h3> minhas campanhas, quando finalizar a criação </h3> */}
                <CampoTexto
                    titulo="Insira sua imagem da campanha"
                    valor={imagem}
                    controle={setImagem}
                    type="file"
                    icone={<ImageSearchOutlinedIcon fontSize="large" />}
                />

                <div className={styles.ContainerImagemCampanha}>
                    <button className="btn btn-success">
                        Enviar
                    </button>
                </div>
            </form>
        </section>
    );
}