import { useLocation } from "react-router";
import styles from "./AdicionarBanners.module.css"
import SendBanner from "./SendBanner";

export default function AdicionarBanners() {
    // Pegar parametro da página
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const emailAnunciante = params.get("email"); 
    const idCampanha = params.get("id"); 
    // Saber se é moderador ou anunciante    
    const tipoUsuario = location.pathname.split('/')[1];
    return (
        <section className={styles.AdicionarBanners}>
            <h2> Adicionar banners </h2>

            <p> Local para adicionar banners que serão disponibilizados para os influenciadores baixarem. </p>

            <SendBanner email={emailAnunciante ? emailAnunciante : ""} idCampaign={idCampanha ? idCampanha : ""}/>
        </section>
    )
}