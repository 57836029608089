import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ICriarCampanha from '../../types/ICriarCampanha';

interface TabelaInfluenciadorProps {
    campanhas?: ICriarCampanha[]
}

export default function TabelaInfluenciador({campanhas}: TabelaInfluenciadorProps) {
    const [tabela, setTabela] = useState(true);   

    console.log("debug", campanhas);
    

    useEffect(() => {
        function largura() {
            if (window.innerWidth < 650) setTabela(false);
        }
        largura();
    }, [])

    return (
        <>
            <h2> Resultado da pesquisa </h2>
            {tabela ? <table className="table table-light table-striped text-center ">
                <thead>
                    <tr>
                        <th scope="col" className="text-white"> Nome </th>
                        <th scope="col" className="text-white"> Tipo </th>
                        {/* <th scope="col" className="text-white"> Payout </th> */}
                        <th scope="col" className="text-white"> Termina </th>                        
                        <th scope="col" className="text-white"> Detalhes </th>
                    </tr>
                </thead>
                {campanhas?.map(cel => (
                    <tbody>
                        <tr>
                            <td> <p> {cel.nomeDaCampanha} </p> </td>
                            <td> <p> {cel.tipoDeCampanha} </p> </td>
                            {/* <td> R$250,00 </td> */}
                            <td>  {cel.fimInscricao.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3/$2/$1')} </td>                            
                            <td>
                                <div className="divAcoesTabela">
                                    <a href={`/influenciador/detalhes-campanha?id=${cel.id}`}> <ArrowForwardOutlinedIcon /> </a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                ))}
            </table> : <div>
                {campanhas?.map(cel => (
                    <ul className="lista">
                        <li className="Itemlista"> . </li>                        
                        <li className="Itemlista"> <strong> Nome: </strong> {cel.nomeDaCampanha}  </li>
                        {/* <li className="Itemlista"> <strong> Payout: </strong> R$250,00 </li> */}
                        <li className="Itemlista"> <strong> Termina: </strong> {cel.fimInscricao.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3/$2/$1')} </li>                        
                        <li className="Itemlista">
                            <div className="divAcoes">
                                <strong> Ação: </strong>
                                <a href={`/influenciador/detalhes-campanha?id=${cel.id}`}> <ArrowForwardOutlinedIcon /> </a>                      
                            </div>
                        </li>
                    </ul>
                ))}
            </div>}

        </>
    )
}