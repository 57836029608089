import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

// Função para verificar se o parâmetro openExternalBrowser está presente e redirecionar se necessário
function openExternalBrowser() {
  const urlParams = new URLSearchParams(window.location.search);
  const currentUrl = window.location.href.split('?')[0];
  
  if (urlParams.has('openExternalBrowser') && currentUrl !== 'https://influenciesuamarca.com.br') {
    console.log('Redirecionando para a página inicial...');
    window.location.href = 'https://influenciesuamarca.com.br';
  }
}

// Chama a função de redirecionamento antes de renderizar a aplicação
openExternalBrowser();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
