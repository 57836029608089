import { collection, getDocs } from "firebase/firestore/lite";
import { useEffect, useState } from "react";
import { db } from "../../../db/banco";
import aceitoStore from "../../../stores/aceito";
import INotificacao from "../../../types/INotificao";
import UsuarioNaoAceitoAnunciante from "../../UsuarioNaoAceito/UsuarioNaoAceitoAnunciante";
import styles from "./NotificacoesAnunciante.module.css";
import autenticaStore from "../../../stores/autentica.store";
import { Stack, Pagination } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';

export default function NotificacoesAnunciante() {
    // obtem o email(serve como id) do anunciante
    const email = autenticaStore.getEmail();
    // verifica se usuário foi aceito na plataforma
    const statusPlataforma = aceitoStore.getStatus();
    // recebe as notificacões
    const [notificacoes, setNotificacoes] = useState<INotificacao[]>([]);
    // navegação
    const navigate = useNavigate();

    // banco de dados
    const useCollectionRefNotificacao = collection(db, "notificacao");

    useEffect(() => {
        const obterUsuario = async () => {
            const dataBD = await getDocs(useCollectionRefNotificacao);
            const todasNotificacoes: INotificacao[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as INotificacao);
            const somenteDesseAnunciante = todasNotificacoes.filter(desseAnunciante => desseAnunciante.emailDeQuemVaiReceberNotificacao === email);

            setNotificacoes(somenteDesseAnunciante);
            

        };
        obterUsuario();
    }, []);

    // Paginação
    const [pagina, setPagina] = useState(1);

    const mudarPagina = (event: any, value: any) => {
        setPagina(value);
    };

    const linhasPorPagina = 10;
    const startIndex = (pagina - 1) * linhasPorPagina;
    const endIndex = startIndex + linhasPorPagina;
    const notificacoesPaginadas = notificacoes.slice(startIndex, endIndex);

    return (
        <>
            {statusPlataforma === "aceito" ?
                <section className={styles.NotificacoesAnunciante}>
                    <h2> Notificações do anunciante </h2>

                    {notificacoesPaginadas.length > 0 ? <Stack spacing={2}>
                <table className="table table-light table-striped text-center">
                    <thead>
                        <tr>
                            <th scope="col" className="text-white"> Notificação </th>                            
                            <th scope="col" className="text-white"> Detalhes </th>                            
                            <th scope="col" className="text-white"> Status </th>
                        </tr>
                    </thead>
                    <tbody>
                        {notificacoesPaginadas.map((cel, index) => (
                            <tr key={cel.id} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }} >
                                <td> {cel.mensagem} </td>                  
                                <td> <a href={cel.link}> <ArrowOutwardIcon /> </a> </td> 
                                <td className={cel.visto ? styles.Visto : styles.Nova}> {cel.visto ? "Visto" : "Nova"} </td>                               
                            </tr>
                        ))}
                    </tbody>
                </table>

                <Pagination
                    count={Math.ceil(notificacoes.length / linhasPorPagina)}
                    page={pagina}
                    onChange={mudarPagina}
                />
            </Stack> : <p> Nenhuma notificação por enquanto. </p>}

                </section>
                :
                <UsuarioNaoAceitoAnunciante />}
        </>
    )
}