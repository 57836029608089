import { collection, getDocs } from "firebase/firestore/lite";
import { useEffect, useState } from "react";
import { db } from "../../../../db/banco";
import ICriarCampanha from "../../../../types/ICriarCampanha";
import { listaTiposDeCampanha } from "../../../../utils/listas";
import styles from "./CampanhasSemPerformanceMod.module.css";
import TableDesktop from "./TableDesktop";
import Spinner from "../../../../components/Spinner";

export default function CampanhasSemPerformanceMod() {
    const [campanhas, setCampanhas] = useState<ICriarCampanha[]>([]);
    const [updateLoading, setUpadateLoading] = useState(true);

    const useCollectionRef = collection(db, "criarCampanha");
    useEffect(() => {
        const obterCampanhas = async () => {
            const dataBD = await getDocs(useCollectionRef);
            const todasCampanhas: ICriarCampanha[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id } as ICriarCampanha));

            const todasCampanhasSemPerformance = todasCampanhas.filter(campanhas => campanhas.tipoDeCampanha === listaTiposDeCampanha[0]
                || campanhas.tipoDeCampanha === listaTiposDeCampanha[1]);
            setCampanhas(todasCampanhasSemPerformance);
            setUpadateLoading(false);            
        }
        obterCampanhas();

    }, []);

    return (
        <section className={styles.CampanhasSemPerformanceMod}>
            <h2> Campanhas sem Perfomance </h2>

            {updateLoading ? <Spinner /> : <TableDesktop data={campanhas} />}
        </section>
    )
}