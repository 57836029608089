import { Link, useNavigate } from "react-router-dom";
import styles from "./AceitarInfluenciadores.module.css"
import { collection, doc, getDocs, updateDoc } from "firebase/firestore/lite";
import { db } from "../../../../../db/banco";
import { useEffect, useState } from "react";
import IInfluenciador from "../../../../../types/IInfluenciador";
import axios from "axios";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import guardaPaginaAnterior from "../../../../../stores/paginaAnterior";
import { TextField as T, Autocomplete } from '@mui/material';
import ChoiceField from "../../../../../components/ChoiceField";
import { listaFiltroModeradorOrdenarPorNumeroDeSeguidores, listaFiltroModeradorPorInfluenciador } from "../../../../../utils/listas";
import { autocompleteStyles } from "../../../../../utils/styles";
import PersonIcon from '@mui/icons-material/Person';
import { listaDeCategorias } from "../../../../../utils/listaDeCategorias";
import BorderAllIcon from '@mui/icons-material/BorderAll';

export default function AceitosInfluenciadores() {
    const navigate = useNavigate();
    // Aux para atualizar página
    const [aux, setAux] = useState(0);
    // pagina
    const [paginaRecebe, setPaginaRecebe] = useState(0);
    const navitate = useNavigate();
    // recebe e controla os influenciadores
    const [influenciadores, setInfluenciadores] = useState<IInfluenciador[]>([]);
    const [influenciadoresAux, setInfluenciadoresAux] = useState<IInfluenciador[]>([]);
    const [influenciadorFiltrado, setInfluenciadorFiltrado] = useState<IInfluenciador[]>([]);
    const useCollectionRefInfluenciador = collection(db, "influenciador");
    // filtro    
    const [filtroPorNome, setFiltroPorNome] = useState("");
    const [filtrarPorEmail, setFiltrarPorEmail] = useState("");
    const [filtrarPorCategoria, setFiltrarPorCategoria] = useState("");
    // Filtros
    const [filtrarPor, setFiltrarPor] = useState("");
    // atualizar tabela
    const [atualiza, setAtualiza] = useState(false);
    // Ordenar
    const [ordenar, setOrdenar] = useState("")
    // obtem o influenciador
    useEffect(() => {
        const obterInfluenciadores = async () => {
            const dataBD = await getDocs(useCollectionRefInfluenciador);
            const todosInfluenciadores: IInfluenciador[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as IInfluenciador);
            const analiseInfluenciaodes: IInfluenciador[] = todosInfluenciadores.filter(analise => analise.aceito === "aceito");
            setInfluenciadores(analiseInfluenciaodes);
            setInfluenciadoresAux(analiseInfluenciaodes);

        };
        obterInfluenciadores();
    }, [atualiza]);

    async function editarInfluenciador(id: string, novosDados: any) {
        try {
            if (id) {
                const chamadoDocRef = doc(db, "influenciador", id);

                await updateDoc(chamadoDocRef, novosDados);
                navitate("/moderador/aceitar-usuarios");
                setAtualiza(!atualiza);
            }
        } catch (error) {
            alert("Nao deu certo")
        }
    }


    const enviarEmailRecusar = async (email: string, nome: string) => {
        try {
            const response = await axios.post(
                "https://us-central1-ism-teste.cloudfunctions.net/enviarEmail",
                {
                    destinatario: email,
                    assunto: "Informações sobre sua análise de cadastro",
                    mensagem: `Olá, ${nome}\n\n` +

                        "Ficamos muito felizes em saber que deseja participar das nossas campanhas! 🎉\n\n" +

                        "Neste momento, infelizmente, nossos objetivos não deram match e seu cadastro ainda não foi aprovado. 😔\n\n" +

                        "Mas não desanime, não!. \n\n" +

                        "Vamos te explicar um pouquinho sobre como funciona essa análise e temos certeza de que em breve vamos caminhar juntos:\n\n" +

                        "Número de Seguidores: Verificamos se o perfil tem um número significativo de seguidores.\n\n" +

                        "Engajamento: Analisamos o nível de engajamento nas postagens, como curtidas, comentários e compartilhamentos.\n" +
                        "bom influenciador tem um alto nível de interação com seus seguidores.\n\n" +

                        "Qualidade do Conteúdo: Observamos a qualidade das fotos, vídeos e legendas. Conteúdo bem produzido e relevante é um\n" +
                        "indicativo de um influenciador profissional. \n\n" +

                        "Consistência: Verificamos a frequência das postagens. Influenciadores costumam postar regularmente para manter o\n" +
                        "engajamento do público.\n\n" +

                        "Autenticidade: Avaliamos se o conteúdo parece autêntico e genuíno. Influenciadores geralmente têm uma voz autêntica\n" +
                        "que ressoa com seus seguidores.\n\n" +

                        "Nicho: Identificamos se o perfil tem um nicho específico, como moda, beleza, fitness, etc. Influenciadores geralmente se\n" +
                        "especializam em um ou mais nichos.\n\n" +

                        "Então, não é um adeus, é um até logo! 💜\n\n" +

                        "Um abraço do time Influencie Sua Marca."
                }
            );

            const { id } = response.data;
            return id;
        } catch (error) {
            // console.log(error);
        }
    };

    async function recusarInfluenciador(id: string, email: string, nome: string) {
        const influenciador = {
            aceito: "recusado",
            jaRecusado: true
        }
        await editarInfluenciador(id, influenciador);
        enviarEmailRecusar(email, nome)
        alert("Influenciador recusado");
    };

    // Paginação

    const [pagina, setPagina] = useState(1);

    const handleChangePagina = (event: any, value: any) => {
        setPagina(value);
    };
    const linhasPorPagina = 10;
    const startIndex = (pagina - 1) * linhasPorPagina;
    const endIndex = startIndex + linhasPorPagina;
    const influenciadoresPaginados = influenciadores.slice(startIndex, endIndex);

    // Filtro
    // Função para pegar somente depois do /
    function extractAfterSlash(url: any) {
        if (!url) return ''; // Se a URL for nula ou indefinida, retorna uma string vazia
        const parts = url.split('/');
        return parts.length > 1 ? parts.pop() : '';
    }
    // voltar pra página
    useEffect(() => {
        const guardaPagina = guardaPaginaAnterior.getPagina();
        const convertePagina = Number(guardaPagina);

        if (convertePagina > 0) {
            setPagina(convertePagina);
            setAux(convertePagina);
        }
    }, [aux]);

    // Botão editar
    function irParaPerfilEditar(email: string) {
        navigate(`/moderador/editar-perfil-influenciador?id=${email}&pg=${pagina}`);
    }

    function filtrar() {
        if (filtrarPor === "nome") {
            let resultadoFiltrado: IInfluenciador[] = [...influenciadoresAux];
            const recebeFiltro: IInfluenciador[] = resultadoFiltrado.filter(porNome => porNome.nomeCompleto === filtroPorNome);
            setInfluenciadorFiltrado(recebeFiltro);
        }

        if (filtrarPor === "email") {
            let resultadoFiltrado: IInfluenciador[] = [...influenciadoresAux];
            const recebeFiltro: IInfluenciador[] = resultadoFiltrado.filter(porNome => porNome.email === filtrarPorEmail);
            setInfluenciadorFiltrado(recebeFiltro);
        }

        if (filtrarPor === "categoria") {
            let resultadoFiltrado: IInfluenciador[] = [...influenciadoresAux];
            const recebeFiltro: IInfluenciador[] = resultadoFiltrado.filter(porNome => porNome.categoria === filtrarPorCategoria);
            setInfluenciadorFiltrado(recebeFiltro);

            if (recebeFiltro.length === 0) alert("Não existe influenciador com essa categoria aceito na plataforma!");
        }
    }

    // Ordenar

    function ordenarPor() {
        const seguidoresPeso: { [key: string]: number } = {
            'Entre 10.000 e 15.000': 1,
            'Entre 15.000 e 20.000': 2,
            'Entre 20.000 e 50.000': 3,
            'Entre 50.000 e 100.000': 4,
            'Entre 100.000 e 1M': 5,
            'Maior que 1M': 6,
        };

        const ordenarPorSeguidoresDesc = (a: { quantidadeDeSeguidores: string }, b: { quantidadeDeSeguidores: string }) => {
            return seguidoresPeso[b.quantidadeDeSeguidores] - seguidoresPeso[a.quantidadeDeSeguidores];
        };

        const ordenarPorSeguidoresAsc = (a: { quantidadeDeSeguidores: string }, b: { quantidadeDeSeguidores: string }) => {
            return seguidoresPeso[a.quantidadeDeSeguidores] - seguidoresPeso[b.quantidadeDeSeguidores];
        };

        if (!ordenar) {
            alert("Selecione a forma que será ordenado!");
            return;
        }
        if (ordenar === "mais seguidores") {
            let resultadoFiltrado: IInfluenciador[] = [...influenciadoresAux];
            const influenciadoresOrdenados = resultadoFiltrado.sort(ordenarPorSeguidoresDesc);
            setInfluenciadores(influenciadoresOrdenados)
        }
        if (ordenar === "menos seguidores") {
            let resultadoFiltrado: IInfluenciador[] = [...influenciadoresAux];
            const influenciadoresOrdenados = resultadoFiltrado.sort(ordenarPorSeguidoresAsc);
            setInfluenciadores(influenciadoresOrdenados)
        }
    }

    return (
        <section className={styles.AceitarInfluenciadores}>
            <h3> Filtrar influenciadores por: </h3>

            <aside>
                <ChoiceField
                    title=""
                    control={setFiltrarPor}
                    icon={<FilterAltIcon fontSize="large" />}
                    list={listaFiltroModeradorPorInfluenciador}
                    defaultValue="Escolha"
                />

                {filtrarPor === "nome" &&
                    <>
                        <Autocomplete
                            sx={autocompleteStyles}
                            freeSolo
                            options={influenciadores.map((option) => option.nomeCompleto)}
                            onInputChange={(event, newInputValue) => {
                                setFiltroPorNome(newInputValue);
                            }}
                            renderInput={(params) => (
                                <T

                                    {...params}
                                    label="Nome do Influenciador"
                                    placeholder="Digite o nome"
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <>
                                                <PersonIcon fontSize="large" />
                                                {params.InputProps.startAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            )}
                        />

                        <button className="btn btn-info btn-lg mb-2 ml-3" onClick={filtrar}> Filtrar </button>

                    </>}

                {filtrarPor === "email" &&
                    <>
                        <Autocomplete
                            sx={autocompleteStyles}
                            freeSolo
                            options={influenciadores.map((option) => option.email)}
                            onInputChange={(event, newInputValue) => {
                                setFiltrarPorEmail(newInputValue);
                            }}
                            renderInput={(params) => (
                                <T

                                    {...params}
                                    label="Email do Influenciador"
                                    placeholder="Digite o e-mail"
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <>
                                                <PersonIcon fontSize="large" />
                                                {params.InputProps.startAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            )}
                        />

                        <button className="btn btn-info btn-lg mb-2 ml-3" onClick={filtrar}> Filtrar </button>

                    </>}

                {filtrarPor === "categoria" &&
                    <>
                        <Autocomplete
                            sx={autocompleteStyles}
                            freeSolo
                            options={listaDeCategorias.map((option) => option)}
                            onInputChange={(event, newInputValue) => {
                                setFiltrarPorCategoria(newInputValue);
                            }}
                            renderInput={(params) => (
                                <T

                                    {...params}
                                    label="Categoria do influenciador"
                                    placeholder="Digite a categoria"
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <>
                                                <PersonIcon fontSize="large" />
                                                {params.InputProps.startAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            )}
                        />

                        <button className="btn btn-info btn-lg mb-2 ml-3" onClick={filtrar}> Filtrar </button>

                    </>}
            </aside>
            <h6> Ordenar por </h6>
            <aside>
                <ChoiceField
                    title=""
                    control={setOrdenar}
                    icon={<BorderAllIcon fontSize="large" />}
                    list={listaFiltroModeradorOrdenarPorNumeroDeSeguidores}
                    defaultValue="Escolha"
                />

                <button className="btn btn-primary btn-lg mb-2 ml-3" onClick={ordenarPor}> Ordenar </button>
            </aside>

            {influenciadorFiltrado.length > 0 && <h6> Resultado da pesquisa </h6>}

            {influenciadorFiltrado.map((influenciadorEncontrado, index) => (
                <div className="table-responsive">
                    <table className="table table-light table-striped text-center">
                        <thead>
                            <tr>
                                <th scope="col" className="text-white" style={{ width: '33%' }}>Nome</th>
                                <th scope="col" className="text-white d-none d-sm-table-cell" style={{ width: '33%' }}>@</th>
                                <th scope="col" className="text-white" style={{ width: '33%' }}>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr key={influenciadorEncontrado.id} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
                                <td className="text-wrap" style={{ wordBreak: 'normal', width: '33%' }}>
                                    <a href={`/moderador/ver-perfil-influenciador?id=${influenciadorEncontrado.id}&pg=${pagina}`}>
                                        {influenciadorEncontrado.nomeCompleto}
                                    </a>
                                </td>
                                <td className="text-wrap d-none d-sm-table-cell" style={{ wordBreak: 'normal', width: '33%' }}>
                                    {extractAfterSlash(influenciadorEncontrado.arrobaPrincipal)}
                                </td>

                                <td className={`${styles.CelulaBotoes} text-wrap`} style={{ width: '33%' }}>
                                    <div className="d-flex justify-content-center justify-content-md-start">
                                        <div className="d-sm-none">
                                            <div className="d-flex flex-column">
                                                <button className="btn btn-danger btn-lg" onClick={() => recusarInfluenciador(influenciadorEncontrado.id, influenciadorEncontrado.email, influenciadorEncontrado.nomeCompleto)}>Recusar</button>
                                                <button className="btn btn-info btn-lg mb-2 ml-3" onClick={() => irParaPerfilEditar(influenciadorEncontrado.email)}>Editar</button>
                                            </div>
                                        </div>
                                        <div className="d-none d-sm-block">
                                            <button className="btn btn-danger btn-lg me-md-1" onClick={() => recusarInfluenciador(influenciadorEncontrado.id, influenciadorEncontrado.email, influenciadorEncontrado.nomeCompleto)}>Recusar</button>
                                            <button className="btn btn-info btn-lg" onClick={() => irParaPerfilEditar(influenciadorEncontrado.email)}>Editar</button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ))}

            <h6> Lista dos influenciadores aceitos </h6>

            {influenciadoresPaginados.length > 0 ? <Stack spacing={2}>
                <div className="table-responsive">
                    <table className="table table-light table-striped text-center">
                        <thead>
                            <tr>
                                <th scope="col" className="text-white" style={{ width: '33%' }}>Nome</th>
                                <th scope="col" className="text-white d-none d-sm-table-cell" style={{ width: '33%' }}>@</th>
                                <th scope="col" className="text-white" style={{ width: '33%' }}>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {influenciadoresPaginados.map((cel, index) => (
                                <tr key={cel.id} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
                                    <td className="text-wrap" style={{ wordBreak: 'normal', width: '33%' }}>
                                        <a href={`/moderador/ver-perfil-influenciador?id=${cel.id}&pg=${pagina}&tp=rec`}>
                                            {cel.nomeCompleto}
                                        </a>
                                    </td>
                                    <td className="text-wrap d-none d-sm-table-cell" style={{ wordBreak: 'normal', width: '33%' }}>
                                        {extractAfterSlash(cel.arrobaPrincipal)}
                                    </td>
                                    <td className={`${styles.CelulaBotoes} text-wrap`} style={{ width: '33%' }}>
                                        <div className="d-flex justify-content-center justify-content-md-start">
                                            <div className="d-sm-none">
                                                <div className="d-flex flex-column">
                                                    <button className="btn btn-danger btn-lg" onClick={() => recusarInfluenciador(cel.id, cel.email, cel.nomeCompleto)}>Recusar</button>
                                                    <button className="btn btn-info btn-lg mb-2 ml-3" onClick={() => irParaPerfilEditar(cel.email)}>Editar</button>
                                                </div>
                                            </div>
                                            <div className="d-none d-sm-block">
                                                <button className="btn btn-danger btn-lg me-md-1" onClick={() => recusarInfluenciador(cel.id, cel.email, cel.nomeCompleto)}>Recusar</button>
                                                <button className="btn btn-info btn-lg" onClick={() => irParaPerfilEditar(cel.email)}>Editar</button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <Pagination
                    count={Math.ceil(influenciadores.length / linhasPorPagina)}
                    page={pagina}
                    onChange={handleChangePagina}
                />
            </Stack> : <p> Não existe influenciadores nessa pesquisa </p>}
        </section>
    )
}