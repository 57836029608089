import styles from "./TabelaFatura.module.css";
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import IChatPropostas from '../../../../types/ICharPropostas';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { Modal, Box, Typography } from '@mui/material';
import { styleModal, styleModalCPF } from '../../../../utils/estilos';
import CampoTexto from "../../../../components/CampoTexto";
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { ref, uploadBytesResumable, getDownloadURL, getStorage, listAll } from "firebase/storage";
import { db, storage } from "../../../../db/banco";
import autenticaStore from "../../../../stores/autentica.store";
import CampoEscolha from "../../../../components/CampoEscolha";
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import { collection, doc, getDocs, updateDoc } from "firebase/firestore/lite";
import IInfluenciador from "../../../../types/IInfluenciador";

interface TabelaInfluenciadorProps {
    campanhas?: IChatPropostas[],
    tem?: boolean,
    listaCampanhas: string[],
}

export default function TabelaFatura({ campanhas, tem, listaCampanhas }: TabelaInfluenciadorProps) {
    // email como id
    const email = autenticaStore.getEmail();
    // recebe o influenciador
    const [Influenciador, setInfluenciador] = useState<IInfluenciador[]>([]);
    const [nomeInfluenciador, setNomeInfluenciador] = useState("");
    const [cnpjInfluenciador, setCnpjInfluenciador] = useState("");
    //
    const [tabela, setTabela] = useState(true);
    const [openModalCPF, setModalCPF] = React.useState(false);
    const [openModalCNPJ, setModalCNPJ] = React.useState(false);
    // nota fiscal
    const [notaFiscal, setNotaFiscal] = useState("");
    const [caminhoPdf, setCaminhoPdf] = useState<string[]>([""]);
    const [nomeCampanhaFiscal, setNomeCampanhaFiscal] = useState("");
    // campanha
    const [nomeCampanha, setNomeCampanha] = useState("");
    const [nomeCampanhaParametro, setNomeCampanhaParametro] = useState("");
    const [valorParametro, setValorParametro] = useState(0);
    const [idPropostaParametro, setIdPropostaParametro] = useState("");
    // chat proposta
    const [idChatProposta, setIdChatProposta] = useState("");
    // navigate
    const navigate = useNavigate();

    // banco de dados
    const useCollectionRefInfluenciador = collection(db, "influenciador");

    // obtem o influenciador
    useEffect(() => {
        const obterUsuario = async () => {
            const dataBD = await getDocs(useCollectionRefInfluenciador);
            const todosInfluenciadores: IInfluenciador[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as IInfluenciador);
            const influenciadorEncontrado = todosInfluenciadores.filter(achou => achou.email === email);

            setInfluenciador(influenciadorEncontrado);
            influenciadorEncontrado.map(aceito => {
                setNomeInfluenciador(aceito.nomeCompleto);
                setCnpjInfluenciador(aceito.cnpj);
            })
        };
        obterUsuario();
    }, [])

    useEffect(() => {
        function largura() {
            if (window.innerWidth < 650) setTabela(false);
        }
        largura();
    }, [])

    useEffect(() => {
        if (email && nomeCampanhaFiscal) {
            const storage = getStorage();
            const listRef = ref(storage, `gs://ism-teste.appspot.com/influenciador/${email}/notaFiscal/${nomeCampanhaFiscal}`);

            listAll(listRef)
                .then((res => {
                    const paths = res.items.map(itemRef => itemRef.fullPath);
                    setCaminhoPdf(paths);
                }));
            console.log("pdfs", caminhoPdf);
            console.log("email", email);
        }
    }, [nomeCampanhaFiscal]);

    const fechaModalCPF = () => {
        setModalCPF(false);
    }

    const abreModalCPF = (nomeCampanha: string, id: string) => {
        setModalCPF(true);
        setNomeCampanha(nomeCampanha)
        setIdChatProposta(id);
    }

    const fechaModalCNPJ = () => {
        setModalCNPJ(false);
    }

    const abreModalCNPJ = (valor: number, nome: string, idProposta: string) => {
        setModalCNPJ(true);
        setNomeCampanhaParametro(nome)
        setValorParametro(valor);
        setIdPropostaParametro(idProposta);
    }

    const uploadNotaFiscal = (event: any) => {
        event.preventDefault();
        // enviarEmail();
        const file = event.target[0]?.files[0];
        if (!file) return;

        const storageRef = ref(storage, `influenciador/${email}/notaFiscal/${nomeCampanha}/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            "state_changed",
            snapshot => {
            },
            error => {
                alert(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then(url => {
                    setNotaFiscal(url);
                    // alert(`Arquivo enviado com sucesso!`);
                    navigate("/influenciador/minhas-campanhas");
                })
            }
        )
        notaFiscalEnviada();
    }

    function baixarPdf(url: any) {
        window.open(url);
    }

    function pegaCaminho(caminho: string) {
        console.log(caminho);
        const caminhoEncontrado = caminho;
        criaDownload(caminhoEncontrado);
    }

    function criaDownload(caminhoRecebido: any) {
        const storage = getStorage();

        getDownloadURL(ref(storage, caminhoRecebido))
            .then((url) => {
                const xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.open('GET', url);
                xhr.send();
                baixarPdf(url);
            });
    }

    // status no banco de dados de nota fiscal emitada

    async function notaFiscalEnviada() {
        // enviarEmail();
        const conversa = {
            notaFiscalEnviada: true
        }
        await editarChamado(conversa)
        alert("Nota fiscal enviada!");

    }

    async function editarChamado(novosDados: any) {
        try {
            if (idChatProposta) {
                const chamadoDocRef = doc(db, "chatPropostas", idChatProposta);

                await updateDoc(chamadoDocRef, novosDados);
                // alert("Dados editados");
            }
        } catch (error) {
            alert("Nao deu certo")
        }
    }

    function notaFiscalCheck(nome: string) {
        alert("Nota fiscal já enviada!");
        setNomeCampanhaFiscal(nome);
    }

    function irParaNotaFiscalCPF() {
        navigate(`/influenciador/criar-nota-fiscal?id=${nomeCampanhaParametro}&valor=${valorParametro}&idProposta=${idPropostaParametro}`);
    }

    return (
        <>
            <h2> Resultado da pesquisa </h2>
            {tabela ? <table className="table table-light table-striped text-center ">
                <thead>
                    <tr>
                        <th scope="col" className="text-white"> Nome </th>
                        <th scope="col" className="text-white"> Valor combinado </th>
                        <th scope="col" className="text-white"> Valor disponível para resgate </th>
                        <th scope="col" className="text-white"> Valor pago </th>
                        <th scope="col" className="text-white"> Ação </th>
                    </tr>
                </thead>
                <div>
                    <Modal
                        open={openModalCPF}
                        onClose={fechaModalCPF}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={styleModal}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                {nomeInfluenciador}, adicione uma nota fiscal!
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                Para solicitar pagamento você precisa criar uma nota fiscal do CNPJ; {cnpjInfluenciador},
                                para a empresa Influencie Sua Marca, com o CNPJ. 153695840001-44,
                                endereço: Av Treze de Maio 23, sala 1216, com o valor igual ao solicitado.
                                E anexar a nota abaixo.
                                <form onSubmit={uploadNotaFiscal} className={styles.FormPdf}>

                                    <CampoTexto
                                        titulo="Insira sua nota fiscal"
                                        valor={notaFiscal}
                                        controle={setNotaFiscal}
                                        type="file"
                                        icone={<FileCopyOutlinedIcon fontSize="large" />}
                                    />

                                    <div className={styles.divBotoesModal}>
                                        <button className="btn btn-primary"> Enviar </button>
                                        <button className="btn btn-secondary" onClick={fechaModalCPF}> Voltar </button>
                                    </div>
                                </form>
                            </Typography>
                        </Box>
                    </Modal>
                </div>
                <div>
                    <Modal
                        open={openModalCNPJ}
                        onClose={fechaModalCNPJ}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={styleModalCPF}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                {nomeInfluenciador}, adicione uma nota fiscal!
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                Clique em criar, para ser direcionado para a criação de seu recibo de mídia.
                                <div className={styles.divBotoesModal}>
                                    <button className="btn btn-primary" onClick={irParaNotaFiscalCPF}> Criar </button>
                                    <button className="btn btn-secondary" onClick={fechaModalCNPJ}> Voltar </button>
                                </div>
                            </Typography>
                        </Box>
                    </Modal>
                </div>
                {campanhas?.map(cel => (
                    <tbody>
                        <tr>
                            <td> <p> {cel.nomeCampanha} </p> </td>
                            <td> R$ {cel.valorCombinado},00 </td>
                            <td> R$ {cel.valorCombinado},00 </td>
                            <td> R$ 0,00 </td>
                            {/* <td>  {cel.criadoEm.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3/$2/$1')} </td>*/}
                            <td>
                                <div className="divAcoesTabela">
                                    <a href={`/influenciador/detalhes-campanha?id=${cel.idCampanha}`}> <ArrowForwardOutlinedIcon /> </a>
                                    {tem && <a href={`/influenciador/enviar-trabalho?id=${cel.idCampanha}`} > <CheckCircleOutlineIcon style={{ color: 'green' }} /> </a>}
                                    {cel.tipoPessoa === "Pessoa jurídica" ? <ReceiptLongIcon style={{ color: "blue" }} onClick={() => abreModalCPF(cel.nomeCampanha, cel.id)} /> : <ReceiptLongIcon style={{ color: "blue" }} onClick={() => abreModalCNPJ(cel.valorCombinado, cel.nomeCampanha, cel.id)} />}
                                    {cel.notaFiscalEnviada && <CheckCircleOutlineIcon style={{ color: "green" }} onClick={() => notaFiscalCheck(cel.nomeCampanha)} />}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                ))}
            </table> : <div>
                <div>
                    <Modal
                        open={openModalCPF}
                        onClose={fechaModalCPF}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={styleModal}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                {nomeInfluenciador}, adicione uma nota fiscal!
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                Para solicitar pagamento você precisa criar uma nota fiscal do CNPJ; {cnpjInfluenciador},
                                para a empresa Influencie Sua Marca, com o CNPJ. 153695840001-44,
                                endereço: Av Treze de Maio 23, sala 1216, com o valor igual ao solicitado.
                                E anexar a nota abaixo.
                                <form onSubmit={uploadNotaFiscal} className={styles.FormPdf}>

                                    <CampoTexto
                                        titulo="Insira sua nota fiscal"
                                        valor={notaFiscal}
                                        controle={setNotaFiscal}
                                        type="file"
                                        icone={<FileCopyOutlinedIcon fontSize="large" />}
                                    />

                                    <div className={styles.divBotoesModal}>
                                        <button className="btn btn-primary"> Enviar </button>
                                        <button className="btn btn-secondary" onClick={fechaModalCPF}> Voltar </button>
                                    </div>
                                </form>
                            </Typography>
                        </Box>
                    </Modal>
                </div>
                <div>
                    <Modal
                        open={openModalCNPJ}
                        onClose={fechaModalCNPJ}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={styleModalCPF}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                {nomeInfluenciador}, adicione uma nota fiscal!
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                Clique em criar, para ser direcionado para a criação de seu recibo de mídia.
                                <div className={styles.divBotoesModal}>
                                    <button className="btn btn-primary" onClick={irParaNotaFiscalCPF}> Criar </button>
                                    <button className="btn btn-secondary" onClick={fechaModalCNPJ}> Voltar </button>
                                </div>
                            </Typography>
                        </Box>
                    </Modal>
                </div>
                {campanhas?.map(cel => (
                    <ul className="lista">
                        <li className="Itemlista"> . </li>
                        <li className="Itemlista"> <strong> Nome: </strong> {cel.nomeCampanha}  </li>
                        <li className="Itemlista"> <strong> Valor combinado </strong> R$ {cel.valorCombinado},00 </li>
                        <li className="Itemlista"> <strong> Valor disponível para resgate </strong> R$ {cel.valorCombinado},00 </li>
                        <li className="Itemlista"> <strong> Valor pago </strong> R$ 0,00 </li>
                        {/* <li className="Itemlista"> <strong> Payout: </strong> R$250,00 </li> */}
                        {/* <li className="Itemlista"> <strong> Termina: </strong> {cel.criadoEm.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3/$2/$1')} </li>*/}

                        <li className="Itemlista">
                            <div className="divAcoes">
                                <strong> Ação: </strong>
                                <a href={`/influenciador/detalhes-campanha?id=${cel.idCampanha}`}> <ArrowForwardOutlinedIcon /> </a>
                                {tem && <a href={`/influenciador/enviar-trabalho?id=${cel.idCampanha}`} > <CheckCircleOutlineIcon style={{ color: 'green' }} /> </a>}
                                {cel.tipoPessoa === "Pessoa jurídica" ? <ReceiptLongIcon style={{ color: "blue" }} onClick={() => abreModalCPF(cel.nomeCampanha, cel.id)} /> : <ReceiptLongIcon style={{ color: "blue" }} onClick={() => abreModalCNPJ(cel.valorCombinado, cel.nomeCampanha, cel.id)} />}
                                {cel.notaFiscalEnviada && <CheckCircleOutlineIcon style={{ color: "green" }} onClick={() => notaFiscalCheck(cel.nomeCampanha)} />}
                            </div>
                        </li>
                    </ul>
                ))}
            </div>}

            <h2> Notas fiscais </h2>

            <form className={styles.FormPdf}>
                <CampoEscolha
                    titulo="Escolha uma campanha"
                    lista={listaCampanhas}
                    icone={<CampaignOutlinedIcon fontSize="large" />}
                    controle={setNomeCampanhaFiscal}
                    valorPadrao="Escolha a campanha"
                />
            </form>

            <ul className={styles.ListaNotaFiscal}>
                {caminhoPdf.length !== 0 ? caminhoPdf.map(pdf => {
                    const partes = pdf.split(`/notaFiscal/${nomeCampanhaFiscal}/`);
                    const nomeArquivo = partes.length > 1 ? partes[1] : pdf; // Pega a parte após '/comprovantesCampanha/' ou usa o caminho completo se não encontrar

                    return (
                        <>
                            <li onClick={() => pegaCaminho(pdf)} key={pdf}>
                                <ReceiptLongIcon fontSize="medium" className={styles.Icone} />
                                <label> {nomeArquivo} </label>
                            </li>
                        </>
                    );
                }) : <p> Nenhuma notal fiscal até o momento, para essa campanha! </p>}
            </ul>

        </>
    )
}