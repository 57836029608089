import React, { useEffect } from 'react';
import AppRoute from "./AppRoute";

// declare global {
//   interface Window {
//     fbq: any;
//     _fbq: any;
//     ttq: any;
//     dataLayer: any;
//     gtag: any;
//   }
// }

function App() {
  // useEffect(() => {
  //   // Meta Pixel Code
  //   (function (f: any, b: any, e: any, v: any, n?: any, t?: any, s?: any) {
  //     if (f.fbq) return;
  //     n = f.fbq = function () {
  //       n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
  //     };
  //     if (!f._fbq) f._fbq = n;
  //     n.push = n;
  //     n.loaded = !0;
  //     n.version = '2.0';
  //     n.queue = [];
  //     t = b.createElement(e);
  //     t.async = !0;
  //     t.src = v;
  //     s = b.getElementsByTagName(e)[0];
  //     s.parentNode.insertBefore(t, s);
  //   })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

  //   window.fbq('init', '2249840558683626');
  //   window.fbq('track', 'PageView');

  //   // TikTok Pixel Code
  //   (function (w: any, d: any, t: any) {
  //     w.TiktokAnalyticsObject = t;
  //     var ttq = w[t] = w[t] || [];
  //     ttq.methods = [
  //       "page", "track", "identify", "instances", "debug", "on", "off", "once",
  //       "ready", "alias", "group", "enableCookie", "disableCookie", "holdConsent",
  //       "revokeConsent", "grantConsent"
  //     ];
  //     ttq.setAndDefer = function (t: any, e: any) {
  //       t[e] = function () {
  //         t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
  //       };
  //     };
  //     for (var i = 0; i < ttq.methods.length; i++) {
  //       ttq.setAndDefer(ttq, ttq.methods[i]);
  //     }
  //     ttq.instance = function (t: any) {
  //       var e = ttq._i[t] || [];
  //       for (var n = 0; n < ttq.methods.length; n++) {
  //         ttq.setAndDefer(e, ttq.methods[n]);
  //       }
  //       return e;
  //     };
  //     ttq.load = function (e: any, n: any) {
  //       var r = "https://analytics.tiktok.com/i18n/pixel/events.js";
  //       var o = n && n.partner;
  //       ttq._i = ttq._i || {};
  //       ttq._i[e] = [];
  //       ttq._i[e]._u = r;
  //       ttq._t = ttq._t || {};
  //       ttq._t[e] = +new Date();
  //       ttq._o = ttq._o || {};
  //       ttq._o[e] = n || {};
  //       n = document.createElement("script");
  //       n.type = "text/javascript";
  //       n.async = !0;
  //       n.src = r + "?sdkid=" + e + "&lib=" + t;
  //       e = document.getElementsByTagName("script")[0];
  //       e.parentNode.insertBefore(n, e);
  //     };

  //     ttq.load('CR1N7GBC77U99EQAUT80');
  //     ttq.page();
  //   })(window, document, 'ttq');

  //   // Google Tag Manager Code
  //   (function (w: any, d: any, s: any, l: any, i: any) {
  //     w[l] = w[l] || [];
  //     w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  //     var f = d.getElementsByTagName(s)[0],
  //       j = d.createElement(s),
  //       dl = l != 'dataLayer' ? '&l=' + l : '';
  //     j.async = true;
  //     j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
  //     f.parentNode.insertBefore(j, f);
  //   })(window, document, 'script', 'dataLayer', 'GTM-N9MCKV88');

  //   // Google Tag (gtag.js) Code
  //   const gtagScript = document.createElement('script');
  //   gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=AW-11360709416';
  //   gtagScript.async = true;
  //   document.head.appendChild(gtagScript);

  //   gtagScript.onload = () => {
  //     window.dataLayer = window.dataLayer || [];
  //     function gtag() { window.dataLayer.push(arguments); }
  //     window.gtag = gtag;
  //     window.gtag('js', new Date());
  //     window.gtag('config', 'AW-11360709416');
  //   };
  // }, []);

  return <AppRoute />;
}

export default App;
