import { useLocation } from "react-router-dom";
import styles from "./VerBanners.module.css";
import LoadBanners from "./LoadBanners";

export default function VerBanners() {
    // Pegar parametro da página
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const emailAnunciante = params.get("email");
    const idCampanha = params.get("id");
    return (
        <section className={styles.VerBanners}>
            <h2> Ver banners </h2>

            <p> Todos os banners adicionados nessa campanha: </p>

            <LoadBanners email={emailAnunciante ? emailAnunciante : ""} idCampaign={idCampanha ? idCampanha : ""}/>
        </section>
    )
}