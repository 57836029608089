import axios from 'axios';

export const buscarDadosApiEstatisicas = async (
    dataInicial: string, // Parâmetro obrigatório
    dataFinal: string,   // Parâmetro obrigatório
    idCampanhaActionpay?: string, // Parâmetro opcional, movido para depois dos obrigatórios
    subId1?: string // Parâmetro opcional
) => {
    try {
        // Construção da URL da chamada da API
        const url = new URL(`https://us-central1-ism-teste.cloudfunctions.net/buscarEstatisticas`);
        url.searchParams.append('from', dataInicial);
        url.searchParams.append('till', dataFinal);

        // Adiciona o offer à URL se estiver definido
        if (idCampanhaActionpay) {
            url.searchParams.append('offer', idCampanhaActionpay);
        }

        // Adiciona o subId1 à URL se estiver definido
        if (subId1) {
            url.searchParams.append('subId1', subId1);
        }

        // Chamada para a função Firebase Functions com os parâmetros
        const response = await axios.get(url.toString());

        // Verifica se há erro na resposta
        if (!response.data.error) {
            return response.data; // Retorna os dados se não houver erro
        } else {
            return undefined; // Retorna undefined em caso de erro na resposta
        }

    } catch (error) {
        return undefined; // Retorna undefined em caso de erro na chamada da API
    }
};

export const buscarObjetivos = async (idCampanhaActionpay: string) => {
    try {
        const url = new URL(`https://us-central1-ism-teste.cloudfunctions.net/verificarMetas`);
        url.searchParams.append('offer', idCampanhaActionpay);

        console.log('URL gerada:', url.toString());

        const response = await axios.get(url.toString());

        if (!response.data.error) {
            console.log('Resposta da API:', response.data);
            return response.data;
        } else {
            console.warn('Erro na resposta da API:', response.data.error);
            return undefined;
        }
    } catch (error: any) {
        console.error('Erro na chamada Axios:', error.message, error.response?.data);
        throw error; // Propaga o erro para ser tratado pelo chamador
    }
};